import jwtAuthentication from './jwtAuthentication'

const setToken = (idToken) => {
  localStorage.setItem('id_token', `Bearer ${idToken}`)
}

const setMenu = (menu) => {
  localStorage.setItem('menu', JSON.stringify(menu))
}

const getToken = () => localStorage.getItem('id_token')

const getMenu = () => localStorage.getItem('menu')

const removeToken = () => {
  localStorage.clear()
}

const isAuth = () => {
  // Checks if there is a saved token and it's still valid
  const token = getToken()

  const localToken = jwtAuthentication.checkExpirity(token)
  return !!token && !!localToken.token // handwaiving here
}

export { setToken, getToken, removeToken, isAuth, setMenu, getMenu }
