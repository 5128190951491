import { actionType } from './auth.action'

const initState = {
  isLoading: false,
  current: null,
  profile: null,
}

export default function reducer(state = initState, action) {
  const { type, payload } = action
  switch (type) {
    case actionType('login').request:
      return {
        ...state,
        isLoading: true,
      }
    case actionType('login').success:
      return {
        ...state,
        isLoading: false,
      }
    case actionType('login').error:
      return {
        ...state,
        isLoading: false,
      }

    case actionType('profile').success:
      return {
        ...state,
        profile: payload.data,
      }
    default:
      return state
  }
}
