import { URL_API } from 'src/configs/keys'
import httpService from 'src/helpers/httpService'
import baseActions from 'src/helpers/redux/baseActions'

const master = 'transaction'

export const actionType = (action) => {
  return {
    request: `${action}_${master}_request`.toUpperCase(),
    success: `${action}_${master}_success`.toUpperCase(),
    error: `${action}_${master}_error`.toUpperCase(),
  }
}

const request = httpService()

export default {
  ...baseActions(master, URL_API, actionType),
  updateData: (data) => {
    return (dispatch) => {
      dispatch({ type: actionType('update').request })
      return request.put(`${URL_API}/${master}?ids=${data}`, {}).then(
        (response) => {
          dispatch({ type: actionType('update').success })
          return response.data
        },
        (error) => {
          dispatch({ type: actionType('update').error })
          throw error.response
        },
      )
    }
  },
}
