import { URL_API, URL_API_MASTER_DATA } from 'src/configs/keys'
import httpService from 'src/helpers/httpService'
import baseActions from 'src/helpers/redux/baseActions'

const master = 'member/old'
const masterCreate = 'member/reactivate'
const profession = 'master/profession'

export const actionType = (action) => {
  return {
    request: `${action}_${master}_request`.toUpperCase(),
    success: `${action}_${master}_success`.toUpperCase(),
    error: `${action}_${master}_error`.toUpperCase(),
  }
}

const request = httpService()

export default {
  ...baseActions(master, URL_API, actionType),
  createData: (data) => {
    return (dispatch) => {
      dispatch({ type: actionType('create').request })
      return request.post(`${URL_API}/${masterCreate}`, data).then(
        (response) => {
          dispatch({ type: actionType('create').success })
          return response.data
        },
        (error) => {
          dispatch({ type: actionType('create').error })
          throw error.response
        },
      )
    }
  },
  fetchProfession: (params) => {
    return (dispatch) => {
      dispatch({ type: actionType('fetchProfession').request })
      return request.get(`${URL_API_MASTER_DATA}/${profession}`, { params }).then(
        (response) => {
          return response.data
        },
        (error) => {
          throw error.response
        },
      )
    }
  },
}
