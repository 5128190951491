import baseInitState from 'src/helpers/redux/baseInitState'
import baseReducer from 'src/helpers/redux/baseReducer'

import { actionType } from './banner.action'

const initState = {
  ...baseInitState,
}

export default function reducer(state = initState, action) {
  switch (action.type) {
    case actionType('action').success:
      return {
        ...state,
      }

    default:
      return baseReducer(state, action, actionType)
  }
}
