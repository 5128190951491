import { URL_API } from 'src/configs/keys'
import httpService from 'src/helpers/httpService'
import { setMenu, setToken } from 'src/helpers/token'

const master = 'auth'

export const actionType = (action) => {
  return {
    request: `${action}_${master}_request`.toUpperCase(),
    success: `${action}_${master}_success`.toUpperCase(),
    error: `${action}_${master}_error`.toUpperCase(),
  }
}

const request = httpService()
export default {
  login: (data) => {
    return (dispatch) => {
      dispatch({ type: actionType('login').request })
      return request.post(`${URL_API}/auth`, data).then(
        (response) => {
          dispatch({ type: actionType('login').success })
          setToken(response.data.data.access_token)
          return response.data
        },
        (error) => {
          dispatch({ type: actionType('login').error })
          throw error.response
        },
      )
    }
  },
  getToken: () => {
    return (dispatch) => {
      return request.get(`${URL_API}/admin/me`).then(
        (response) => {
          dispatch({ type: actionType('profile').success, payload: response.data })
          if (response.data?.status_code === 200) {
            setMenu(response?.data?.data?.menu)
          }
          return response.data
        },
        (error) => {
          throw error.response
        },
      )
    }
  },
  updateProfile: (data) => {
    return () => {
      return request.put(`${URL_API}/user/me`, data).then(
        (response) => {
          return response.data
        },
        (error) => {
          throw error.response
        },
      )
    }
  },
  updatePassword: (data) => {
    return () => {
      return request.post(`${URL_API}/auth/change-password`, data).then(
        (response) => {
          return response.data
        },
        (error) => {
          throw error.response
        },
      )
    }
  },
  forgotPassword: (data) => {
    return () => {
      return request.post(`${URL_API}/auth/forgot-password`, data).then(
        (response) => {
          return response.data
        },
        (error) => {
          throw error.response
        },
      )
    }
  },
  resendOTP: () => {
    return () => {
      return request.post(`${URL_API}/otp/resend`).then(
        (response) => {
          return response.data
        },
        (error) => {
          throw error.response
        },
      )
    }
  },
  otpVerify: (data) => {
    return () => {
      return request.post(`${URL_API}/auth/forgot-password/verify`, data).then(
        (response) => {
          return response.data
        },
        (error) => {
          throw error.response
        },
      )
    }
  },
  resetPassword: (data) => {
    return () => {
      return request.post(`${URL_API}/auth/forgot-password/new-password`, data).then(
        (response) => {
          return response.data
        },
        (error) => {
          throw error.response
        },
      )
    }
  },
}
