export default {
  isLoading: false,
  isLoadingDetail: false,
  isLoadingSubmit: false,
  isLoadingDelete: false,
  dataAll: [],
  dataList: [],
  dataForm: null,
  dataDetail: null,
  pagination: {
    currentPage: 1,
    lastPage: 1,
    count: 0,
    recordPerPage: 5,
  },
  menuActions: [],
  actions: {},
  params: {
    page: 1,
    limit: 5,
    sort_by: 'created_at',
    order: 'desc',
  },
}
