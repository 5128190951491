import { URL_API, URL_API_MASTER_DATA } from 'src/configs/keys'
import httpService from 'src/helpers/httpService'
import baseActions from 'src/helpers/redux/baseActions'

const master = 'member'
const profession = 'master/profession'

export const actionType = (action) => {
  return {
    request: `${action}_${master}_request`.toUpperCase(),
    success: `${action}_${master}_success`.toUpperCase(),
    error: `${action}_${master}_error`.toUpperCase(),
  }
}

const request = httpService()

export default {
  ...baseActions(master, URL_API, actionType),
  fetchProfession: (params) => {
    return (dispatch) => {
      dispatch({ type: actionType('fetchProfession').request })
      return request.get(`${URL_API_MASTER_DATA}/${profession}`, { params }).then(
        (response) => {
          return response.data
        },
        (error) => {
          throw error.response
        },
      )
    }
  },
  printDigitalCard: (user_id) => {
    return (dispatch) => {
      dispatch({ type: actionType('printDigitalCard').request })
      return request.get(`${URL_API}/${master}/id/${user_id}/card`, { responseType: 'blob' }).then(
        (response) => {
          const pdfBlob = new Blob([response.data], { type: 'application/pdf' })
          const pdfUrl = URL.createObjectURL(pdfBlob)
          dispatch({ type: actionType('printDigitalCard').success, payload: pdfUrl })
          return pdfUrl
        },
        (error) => {
          dispatch({ type: actionType('printDigitalCard').error, payload: error.response })
          throw error.response
        },
      )
    }
  },
}
