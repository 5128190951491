import { URL_API, URL_API_MASTER_DATA } from 'src/configs/keys'
import httpService from 'src/helpers/httpService'
import baseActions from 'src/helpers/redux/baseActions'

const master = 'admin'
const dpd = 'master/level-area'

export const actionType = (action) => {
  return {
    request: `${action}_${master}_request`.toUpperCase(),
    success: `${action}_${master}_success`.toUpperCase(),
    error: `${action}_${master}_error`.toUpperCase(),
  }
}

const request = httpService()

export default {
  ...baseActions(master, URL_API, actionType),
  fetchDPD: (params) => {
    return (dispatch) => {
      dispatch({ type: actionType('fetchDPD').request })
      return request.get(`${URL_API_MASTER_DATA}/${dpd}`, { params }).then(
        (response) => {
          return response.data
        },
        (error) => {
          throw error.response
        },
      )
    }
  },
  sendMail: (id) => {
    return (dispatch) => {
      dispatch({ type: actionType('sendMail').request })
      return request.put(`${URL_API}/admin/id/${id}/sendmail`).then(
        (response) => {
          return response.data
        },
        (error) => {
          throw error.response
        },
      )
    }
  },
}
