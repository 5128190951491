import httpService from '../httpService'
import { alertSuccess } from '../swalAlert'
import { getMenu } from '../token'

const baseActions = (master, baseUrl, actionType) => {
  const request = httpService()
  return {
    fetchList: (params) => {
      return (dispatch) => {
        dispatch({ type: actionType('fetchList').request })
        return request.get(`${baseUrl}/${master}`, { params }).then(
          (response) => {
            dispatch({ type: actionType('fetchList').success, payload: response.data, params })
            return response.data
          },
          (error) => {
            dispatch({ type: actionType('fetchList').error })
            throw error.response
          },
        )
      }
    },
    fetchListPartner: (id) => {
      return (dispatch) => {
        dispatch({ type: actionType('fetchList').request })
        return request.get(`${baseUrl}/${master}/${id}/partner`).then(
          (response) => {
            dispatch({
              type: actionType('fetchList').success,
              payload: response.data,
            })
            return response.data
          },
          (error) => {
            dispatch({ type: actionType('fetchList').error })
            throw error.response
          },
        )
      }
    },
    createListPartner: (id, data) => {
      return (dispatch) => {
        dispatch({ type: actionType('create').request })
        return request.post(`${baseUrl}/${master}/${id}/partner`, data).then(
          (response) => {
            dispatch({ type: actionType('create').success })
            return response.data
          },
          (error) => {
            dispatch({ type: actionType('create').error })
            throw error.response
          },
        )
      }
    },
    fetchAll: (params) => {
      return (dispatch) => {
        dispatch({ type: actionType('fetchAll').request })
        return request.get(`${baseUrl}/${master}/all`, { params }).then(
          (response) => {
            dispatch({ type: actionType('fetchAll').success, payload: response.data })
            return response.data
          },
          (error) => {
            dispatch({ type: actionType('fetchAll').error })
            throw error.response
          },
        )
      }
    },

    fetchAllCustome: (params) => {
      return (dispatch) => {
        dispatch({ type: actionType('fetchAll').request })
        return request.get(`${baseUrl}/${master}`, { params }).then(
          (response) => {
            dispatch({
              type: actionType('fetchAll').success,
              payload: response.data,
            })
            return response.data
          },
          (error) => {
            dispatch({ type: actionType('fetchAll').error })
            throw error.response
          },
        )
      }
    },

    fetchDetail: (id, params) => {
      return (dispatch) => {
        dispatch({ type: actionType('fetchDetail').request })
        return request.get(`${baseUrl}/${master}/id/${id}`, { params }).then(
          (response) => {
            dispatch({ type: actionType('fetchDetail').success, payload: response.data })
            return response.data
          },
          (error) => {
            dispatch({ type: actionType('fetchDetail').error })
            throw error.response
          },
        )
      }
    },
    customeDetail: (data) => {
      return (dispatch) => {
        dispatch({ type: actionType('fetchDetail').success, payload: data })
      }
    },
    fetchCustomeDetail: (path, params) => {
      return (dispatch) => {
        dispatch({ type: actionType('fetchCustomeDetail').request })
        return request.get(`${baseUrl}/${path}`, { params }).then(
          (response) => {
            dispatch({ type: actionType('fetchCustomeDetail').success, payload: response.data })
            return response.data
          },
          (error) => {
            dispatch({ type: actionType('fetchCustomeDetail').error })
            throw error.response
          },
        )
      }
    },

    createData: (data) => {
      return (dispatch) => {
        dispatch({ type: actionType('create').request })
        return request.post(`${baseUrl}/${master}`, data).then(
          (response) => {
            dispatch({ type: actionType('create').success })
            return response.data
          },
          (error) => {
            dispatch({ type: actionType('create').error })
            throw error.response
          },
        )
      }
    },

    updateData: (id, data) => {
      return (dispatch) => {
        dispatch({ type: actionType('update').request })
        return request.put(`${baseUrl}/${master}/id/${id}`, data).then(
          (response) => {
            dispatch({ type: actionType('update').success })
            return response.data
          },
          (error) => {
            dispatch({ type: actionType('update').error })
            throw error.response
          },
        )
      }
    },

    deleteData: (id, title) => {
      return (dispatch) => {
        dispatch({ type: actionType('delete').request })
        return request.delete(`${baseUrl}/${master}/id/${id}`).then(
          (response) => {
            dispatch({ type: actionType('delete').success })
            if (title) alertSuccess(title)
            return response.data
          },
          (error) => {
            dispatch({ type: actionType('delete').error })
            throw error.response
          },
        )
      }
    },

    resetParams: () => (dispatch) => {
      dispatch({ type: actionType('resetParams').success })
    },

    setForm: (data) => (dispatch) => {
      dispatch({ type: actionType('setDataForm').success, dataForm: data })
    },
    getAccessMenu: (identify_code) => (dispatch) => {
      let menu = JSON.parse(getMenu())
      if (menu && menu.length > 0) {
        menu = menu.filter((item) => item?.identify_code === identify_code)
        dispatch({ type: actionType('getAccessMenu').success, menu })
      }
    },
    getActionMenu: () => (dispatch) => {
      dispatch({ type: actionType('getActionMenu').success })
    },
  }
}
export default baseActions
