import Swal from 'sweetalert2'

export const alertSuccess = (text) => {
  return Swal.fire({
    html: `
    <div class="d-flex align-items-center mt-2">
      <img src="/images/icons/check-circle.svg" width="38" class="mr-3" />
      ${text || 'Sukses'}
    </div>
    `,
    confirmButtonColor: '#321fdb',
    confirmButtonText: 'Oke',
  })
}
export const alertError = (text) => {
  return Swal.fire({
    html: `
    <div class="d-flex align-items-center mt-2">
      <img src="/images/icons/x-circle.svg" width="38" class="mr-3" />
      ${text || 'Error'}
    </div>
    `,
    confirmButtonColor: '#321fdb',
    confirmButtonText: 'Oke',
  })
}
export const alertWarning = (text) => {
  return Swal.fire({
    html: `
    <div class="d-flex align-items-center mt-2">
      <img src="/images/icons/warning.svg" width="38" class="mr-3" />
      ${text || 'Warning'}
    </div>
    `,
    confirmButtonColor: '#321fdb',
    confirmButtonText: 'Oke',
  })
}
