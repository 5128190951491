module.exports = {
  URL_API_MASTER_DATA: `${process.env.REACT_APP_URL_API}/v1/api`,
  URL_API: `${process.env.REACT_APP_URL_API}/v1/api-admin`,
  URL_API_V2: `${process.env.REACT_APP_URL_API}/v2/api-admin`,
  BASIC_USERNAME: process.env.REACT_APP_BASIC_USERNAME,
  BASIC_PASSWORD: process.env.REACT_APP_BASIC_PASSWORD,
  AES_KEY: process.env.REACT_APP_AES_KEY,
  AES_IV: process.env.REACT_APP_AES_IV,
  LANDING_URL: process.env.REACT_APP_LANDING_URL,
}
