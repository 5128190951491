import baseInitState from './baseInitState'

const baseReducer = (state, action, actionType) => {
  switch (action.type) {
    case actionType('fetchList').request:
      return {
        ...state,
        isLoading: true,
      }
    case actionType('fetchList').success:
      const limit = action.payload?.meta?.limit
      const offset = action.payload?.meta?.offset
      const count = action.payload?.meta?.count

      const currentPage = Math.floor(offset / limit) + 1
      const lastPage = Math.ceil(count / limit)
      return {
        ...state,
        isLoading: false,
        dataList: action.payload.data ? action.payload.data : [],
        pagination: {
          currentPage: currentPage || 1,
          lastPage: lastPage || 1,
          count: count || 0,
          recordPerPage: limit || 10,
        },
        params: action.params,
      }
    case actionType('fetchList').error:
      return {
        ...state,
        isLoading: false,
      }

    case actionType('fetchAll').request:
      return {
        ...state,
        isLoading: true,
      }
    case actionType('fetchAll').success:
      return {
        ...state,
        isLoading: false,
        dataAll: action.payload.data ? action.payload.data : [],
      }
    case actionType('fetchAll').error:
      return {
        ...state,
        isLoading: false,
      }

    case actionType('fetchDetail').request:
      return {
        ...state,
        isLoadingDetail: true,
      }
    case actionType('fetchDetail').success:
      return {
        ...state,
        isLoadingDetail: false,
        dataDetail: action.payload.data,
      }
    case actionType('fetchDetail').error:
      return {
        ...state,
        isLoadingDetail: false,
        dataDetail: null,
      }

    case actionType('create').request:
      return {
        ...state,
        isLoadingSubmit: true,
      }
    case actionType('create').success:
      return {
        ...state,
        isLoadingSubmit: false,
      }
    case actionType('create').error:
      return {
        ...state,
        isLoadingSubmit: false,
      }

    case actionType('update').request:
      return {
        ...state,
        isLoadingSubmit: true,
      }
    case actionType('update').success:
      return {
        ...state,
        isLoadingSubmit: false,
      }
    case actionType('update').error:
      return {
        ...state,
        isLoadingSubmit: false,
      }

    case actionType('delete').request:
      return {
        ...state,
        isLoadingDelete: true,
      }
    case actionType('delete').success:
      return {
        ...state,
        isLoadingDelete: false,
      }
    case actionType('delete').error:
      return {
        ...state,
        isLoadingDelete: false,
      }
    case actionType('resetParams').success:
      return baseInitState
    case actionType('setDataForm').success:
      return {
        ...state,
        ...action.dataForm,
      }
    case actionType('getAccessMenu').success:
      return {
        ...state,
        menuActions: action.menu,
      }
    case actionType('getActionMenu').success:
      if (state.menuActions && state.menuActions.length > 0) {
        const transformedData = state.menuActions[0]?.menu_actions?.reduce((result, action) => {
          const { action_code: actionCode, status } = action
          result[actionCode] = status
          return result
        }, {})

        return {
          ...state,
          actions: transformedData,
        }
      }
      return {
        ...state,
      }
    default:
      return state
  }
}
export default baseReducer
