import { actionType } from './file.action'

const initState = {
  isLoading: false,
  isLoadingDownload: false,
}

export default function reducer(state = initState, action) {
  switch (action.type) {
    case actionType('upload').request:
      return {
        ...state,
        isLoading: true,
      }
    case actionType('upload').success:
      return {
        ...state,
        isLoading: false,
      }
    case actionType('upload').error:
      return {
        ...state,
        isLoading: false,
      }
    case actionType('download_url').request:
      return {
        ...state,
        isLoadingDownload: true,
      }
    case actionType('download_url').success:
    case actionType('download_url').error:
      return {
        ...state,
        isLoadingDownload: false,
      }
    default:
      return state
  }
}
