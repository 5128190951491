export const translateMessage = (type) => {
  switch (type) {
    case 'cannot delete this partner':
      return 'Data tidak dapat dihapus, karena Data Partner sudah memiliki Daftar Pengajuan'
    case 'cannot delete this master partner':
      return 'Data tidak dapat dihapus, karena Data Master Porsi Haji sudah memiliki Daftar Pengajuan'
    case 'duplicate_email':
      return 'Email yang sudah terdaftar'
    case 'invalid_credential':
      return 'Email atau password salah'
    case 'record_already_use':
      return 'Data sudah digunakan'
    default:
      return type
  }
}
