import axios from 'axios'

import { alertError } from './swalAlert'
import { getToken, removeToken } from './token'
import { translateMessage } from './translateMessage'

const handleURL = (url) => {
  const urlArr = ['/otp/verify', '/otp/resend', '/forgot-password', '/reset']
  const data = urlArr.find((x) => url.includes(x))
  return data
}

const handleURLAuth = (url) => {
  const urlArr = ['/auth']
  const data = urlArr.find((x) => url.includes(x))
  return data
}

const httpService = (token) => {
  const service = axios.create()

  service.interceptors.request.use(
    function (config) {
      let currentToken = token || getToken()
      if (handleURL(config.url)) {
        currentToken = window.localStorage.getItem('token')
      }
      if (currentToken) config.headers.Authorization = currentToken
      return config
    },
    function (error) {
      return Promise.reject(error)
    },
  )

  service.interceptors.response.use(
    (response) => {
      return response
    },
    (error) => {
      if (error.response && error.response.status === 401) {
        const { config } = error.response
        if (handleURLAuth(config.url) === '/auth') {
          removeToken()
          return
        }

        if (!handleURL(config.url)) {
          removeToken()
          return (window.location.href = '/')
        }
      } else if (error.message && error.message === 'Network Error') {
        alertError('Connection lost, please check your internet!')
        return
      } else if (error.response.config.responseType === 'blob') {
        if (error.response.status === 422) alertError('Error!, Belum ada transaksi...')
        else alertError('Error!, coba lagi ...')
      } else if (error.response) {
        alertError(translateMessage(error?.response.data?.message))
      }
      return Promise.reject(error)
    },
  )
  return service
}

export default httpService
