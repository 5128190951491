import React from 'react'

import 'core-js'
import 'react-app-polyfill/ie11'
// For IE 11 support
import 'react-app-polyfill/stable'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'

import { icons } from './assets/icons'
import App from './container/App'
import './container/polyfill'
import store from './stores/store'

React.icons = icons

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root'),
)
