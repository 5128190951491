import { combineReducers } from 'redux'
import { reducer as form } from 'redux-form'

import admin from './admin/admin.reducer'
import anggota from './anggota/anggota.reducer'
import auth from './auth/auth.reducer'
import banner from './banner/banner.reducer'
import blog from './blog-galery/blog/blog.reducer'
import blogCategory from './blog-galery/blog_category/blogCategory.reducer'
import galery from './blog-galery/galery/galery.reducer'
import galeryCategory from './blog-galery/galery_category/galeryCategory.reducer'
import configIuran from './config-iuran/configiuran.reducer'
import contentCompro from './contentCompro/contentCompro.reducer'
import eventCategory from './event-category/event-category.reducer'
import event from './event/event.reducer'
import file from './file/file.reducer'
import header from './header/header.reducer'
import kepustakaan from './kepustakaan/kepustakaan.reducer'
import master from './master/master.reducer'
import menu from './menu/menu.reducer'
import pembayaran from './pembayaran/pembayaran.reducer'
import reaktivasi from './reaktivasi/reaktivasi.reducer'
import role from './role/role.reducer'
import sidebar from './sidebar/sidebar.reducer'
import user from './user/user.reducer'

export default combineReducers({
  form,
  sidebar,
  header,
  auth,
  file,
  user,
  role,
  menu,
  admin,
  anggota,
  pembayaran,
  configIuran,
  master,
  contentCompro,
  blog,
  galery,
  blogCategory,
  galeryCategory,
  reaktivasi,
  eventCategory,
  event,
  kepustakaan,
  banner,
})
