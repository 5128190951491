import React from 'react'

import { BrowserRouter, Route, Switch } from 'react-router-dom'

import 'src/assets/styles/style.scss'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse" />
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('src/container/TheLayout'))

// Pages
const Login = React.lazy(() => import('src/views/login/Login'))
const ForgotPassword = React.lazy(() => import('src/views/forgotPassword/ForgotPassword'))
const VerifikasiEmail = React.lazy(() => import('src/views/verifikasiEmail/VerifikasiEmail'))
const NewPassword = React.lazy(() => import('src/views/newPassword/NewPassword'))

const App = () => (
  <BrowserRouter>
    <React.Suspense fallback={loading}>
      <Switch>
        <Route exact path="/login" name="Login Page" render={(props) => <Login {...props} />} />
        <Route
          exact
          path="/forgot-password"
          name="Forgot Password"
          render={(props) => <ForgotPassword {...props} />}
        />
        <Route
          exact
          path="/verifikasi-email"
          name="Verifikasi Email"
          render={(props) => <VerifikasiEmail {...props} />}
        />
        <Route
          exact
          path="/new-password"
          name="New Password"
          render={(props) => <NewPassword {...props} />}
        />

        <Route path="/" name="Home" render={(props) => <TheLayout {...props} />} />
      </Switch>
    </React.Suspense>
  </BrowserRouter>
)

export default App
